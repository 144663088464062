import { render, staticRenderFns } from "./MobPage.vue?vue&type=template&id=f2cb8996"
import script from "./MobPage.vue?vue&type=script&lang=js"
export * from "./MobPage.vue?vue&type=script&lang=js"
import style0 from "./MobPage.vue?vue&type=style&index=0&id=f2cb8996&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports